@import "./pages/Auth/styles.scss";
@import "./pages/Dictionary/styles";
@import "./pages/Dictionary/Verbs/styles";
@import "./pages/Dictionary/Words/styles";
@import "./pages/Game/styles";
@import "./components/Navigation/styles.scss";
@import "./components/Filter/styles.scss";
@import "./components/SpeakButton/styles.scss";
@import "./components/Loading/styles";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #efefef;
    color: #151515;
}

button {
    cursor: pointer;
}

button:hover {
    filter: brightness(110%);
}

button:active {
    filter: brightness(120%);
}