.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  width: 100%;
}
.auth .auth__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 270px;
}
.auth .auth__form .button {
  background-color: teal;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 15px;
}
.auth .auth__form .input {
  border-radius: 6px;
  padding: 12px 20px;
}

.offline {
  margin: 10px 0 20px;
  text-align: center;
}

.fixedSearch {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 10px;
  background-color: #fff;
  z-index: 3;
  box-shadow: 2px 2px 5px rgba(51, 51, 51, 0.1333333333);
  opacity: 95%;
}
.fixedSearch .fixedSearch__button {
  margin-left: 12px;
  border-radius: 6px;
  border: 0;
  padding: 5px 10px;
  background-color: teal;
  color: #fff;
}
.fixedSearch .search {
  margin: 0;
}

.search {
  display: flex;
  margin-top: 20px;
  max-width: 400px;
  margin: auto;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media (max-width: 767px) {
  .search {
    margin-top: 30px;
    border: 0;
    max-width: 100%;
    padding: 0 20px;
  }
}
.search .search__input {
  color: teal;
  outline-color: teal;
  width: 100%;
  font-weight: 800;
  padding: 10px 37px 10px 16px;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(51, 51, 51, 0.4666666667);
}
.search .search__ico {
  position: absolute;
  right: 12px;
  pointer-events: none;
  color: #555;
}
@media (max-width: 767px) {
  .search .search__ico {
    right: 32px;
  }
}

.verbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 50px;
  color: #353535;
}
.verbs .verbs__ul {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  padding: 0;
  list-style: none;
}
.verbs .verbs__ul .ul__header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  font-size: 20px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__header {
    font-size: 13px;
  }
}
.verbs .verbs__ul .ul__li {
  background-color: #fff;
  box-shadow: 3px 2px 8px rgba(20, 20, 20, 0.3);
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.verbs .verbs__ul .ul__li .blured {
  padding: 5px 2px;
  background-color: teal;
  opacity: 30%;
  cursor: pointer;
  filter: blur(7px);
  color: #fff;
}
.verbs .verbs__ul .ul__li .blured:active {
  filter: blur(0px);
  opacity: 100%;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .blured {
    padding: 3px 2px;
  }
  .verbs .verbs__ul .ul__li .blured:hover {
    filter: blur(0px);
    opacity: 100%;
  }
}
.verbs .verbs__ul .ul__li .li__verb {
  display: grid;
  grid-template-columns: 1px repeat(3, 1fr);
  gap: 6px;
  margin-bottom: 3px;
}
.verbs .verbs__ul .ul__li .li__verb .li__speak {
  background: none;
  color: teal;
  z-index: 2;
}
.verbs .verbs__ul .ul__li .li__verb .verb__text {
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: 3px;
  height: 100%;
  font-weight: 700;
  justify-content: center;
  font-size: 20px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__verb .verb__text {
    font-size: 13px;
  }
}
.verbs .verbs__ul .ul__li .li__controller {
  display: flex;
  align-items: stretch;
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate {
  margin: 0 auto 0 0;
  font-size: 20px;
  padding: 3px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate {
    padding: 3px;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(1) {
  margin-right: 5px;
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(2) {
  padding: 3 7px;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__translate :nth-child(2) {
    padding: 2px;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__translate .translate__text {
  background: none;
  color: black;
  opacity: 100%;
}
.verbs .verbs__ul .ul__li .li__controller .controller__button {
  background-color: teal;
  color: #fff;
  min-width: 40px;
  border: none;
  margin-right: 20px;
  border-radius: 8px 8px 0 0;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__button {
    padding: 3px;
    margin-left: 0;
    border-radius: 8px 0 0;
  }
}
.verbs .verbs__ul .ul__li .li__controller .controller__button--remove {
  background-color: rgb(217, 18, 18);
  padding: 0 6px;
  border-radius: 8px 0 0;
  margin-right: 0;
  min-width: 0;
}
@media (max-width: 767px) {
  .verbs .verbs__ul .ul__li .li__controller .controller__button--remove {
    padding: 0 6px;
    border-radius: 0;
  }
}
.verbs .title {
  text-align: center;
  margin-top: 0;
}
.verbs .addingBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}
.verbs .addingBlock .addingBlock__td {
  margin-top: 10px;
}
.verbs .addingBlock .addingBlock__input {
  width: 150px;
  border: none;
  padding: 10px;
  box-shadow: 2px 2px 6px rgba(20, 20, 20, 0.3);
  border-radius: 6px;
}
.verbs .addingBlock .addingBlock__button {
  width: 100px;
  border: none;
  border-radius: 6px;
  background-color: teal;
  color: #fff;
  padding: 10px;
  box-shadow: 2px 2px 6px rgba(20, 20, 20, 0.3);
}

.blur {
  filter: blur(2px);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px 120px;
  min-width: 290px;
  color: #353535;
}
.container .container__learnedButton {
  background-color: teal;
  color: #fff;
  border: none;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
}
.container .addButton {
  background-color: teal;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 2px solid #fff;
  bottom: 0;
  right: 0;
  margin: 20px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 50px;
}
.container .words {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
  background-color: white;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.3333333333);
  border-radius: 5px;
  width: 100%;
  max-width: 700px;
  margin-bottom: 7px;
  border-radius: 6px;
  overflow: hidden;
}
.container .words .words__speak {
  background: none;
  color: teal;
}
.container .words .words__word {
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .container .words .words__word {
    font-size: 15px;
  }
}
.container .words .words__translate {
  display: block;
  filter: blur(4px);
  padding: 5px 0px 5px 10px;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}
.container .words .words__translate:active {
  background-color: #eee;
  filter: blur(0px);
}
@media (max-width: 767px) {
  .container .words .words__translate {
    font-size: 14px;
  }
  .container .words .words__translate:hover {
    filter: blur(0);
  }
}
.container .words .words__button {
  background-color: teal;
  color: #fff;
  border: none;
  margin-left: auto;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .container .words .words__button {
    font-size: 14px;
  }
}
.container .words .words__removeButton {
  background-color: teal;
  color: #fff;
  border: none;
  height: 100%;
  background-color: rgb(217, 18, 18);
}
.container .addWord {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 90px 40px;
  width: 100%;
  max-width: 280px;
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 3px 2px 8px rgba(20, 20, 20, 0.3);
}
.container .addWord .addWord__input {
  border-radius: 6px;
  padding: 0 12px;
  margin-bottom: 9px;
  height: 45px;
  font-size: 15px;
  border: 2px solid #eee;
}
.container .addWord .addWord__button {
  border-radius: 6px;
  padding: 12px 20px;
  margin-bottom: 9px;
  background-color: teal;
  color: #fff;
  border: none;
  border: 1px solid #eee;
}

.error {
  color: #f90000;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 7px;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin: auto;
  font-size: 20px;
}
@media (max-width: 767px) {
  .game {
    font-size: 15px;
  }
}
.game .game__title {
  font-size: 50px;
  margin-top: 10vh;
  text-align: center;
}
@media (max-width: 767px) {
  .game .game__title {
    flex-direction: column;
    font-size: 35px;
  }
}
.game .form {
  display: flex;
  justify-content: center;
  font-size: 30px;
  width: 100%;
}
.game .form .input {
  border: none;
  border-radius: 15px;
  padding: 16px 14px;
  width: 100%;
  max-width: 700px;
  box-shadow: 2px 4px 4px rgba(51, 51, 51, 0.1333333333);
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .game .form .input {
    flex-direction: column;
    font-size: 25px;
    max-width: 100%;
    border-radius: 0;
  }
}
.game .form .input:disabled {
  background-color: white;
}
.game .form .ok {
  outline: 4px solid rgba(0, 255, 0, 0.4666666667);
}
.game .form .mistake {
  outline: 4px solid rgba(255, 0, 0, 0.4666666667);
}
.game .form .warn {
  outline: 4px solid rgba(165, 179, 12, 0.4666666667);
}
.game .button {
  color: #fff;
  outline-color: teal;
  font-weight: 800;
  padding: 14px 24px;
  border: none;
  border-radius: 10px;
  box-shadow: 2px 4px 4px rgba(51, 51, 51, 0.1333333333);
  margin: auto;
}
.game .button--primary {
  padding: 17px 28px;
  margin-top: 15px;
  max-width: 500px;
  width: 50%;
}
@media (max-width: 767px) {
  .game .button--primary {
    margin-top: 25px;
    min-width: 300px;
  }
}
.game .buttonsBlock {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.nav {
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 15px;
}

.nav__button {
  color: #353535;
  text-decoration: none;
  padding: 10px;
  background-color: #eee;
  transition: 0.2s;
  box-shadow: 2px 2px 8px rgba(34, 34, 34, 0.2666666667);
  font-weight: 800;
  border-radius: 5px;
}
.nav__button:hover {
  transform: translateY(-4px);
}

.active {
  background-color: teal;
  color: #fff;
  transform: translateY(-4px);
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 0 20px;
  color: #353535;
  position: fixed;
}
@media (max-width: 1200px) {
  .filter {
    position: relative;
  }
}
.filter .filter__block {
  display: flex;
  align-items: center;
}
.filter .filter__block .block__button {
  padding: 15px;
  border: none;
  box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.4666666667);
  border-radius: 8px;
}
.filter .filter__block .block__text {
  margin-left: 10px;
  font-weight: 700;
}
.filter .filter__block .active {
  background-color: teal;
}

.button {
  background-color: teal;
  color: #fff;
  border: none;
  align-self: stretch;
  padding: auto 5px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.loading .loading__ico {
  color: teal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  color: #151515;
}

button {
  cursor: pointer;
}

button:hover {
  filter: brightness(110%);
}

button:active {
  filter: brightness(120%);
}/*# sourceMappingURL=styles.css.map */