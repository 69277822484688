@import "../../../_theme.scss";

$shadow: 3px 2px 8px rgba(20, 20, 20, 0.3);
$shadowLite: 2px 2px 6px rgba(20, 20, 20, 0.3);

@mixin text {
    font-size: 20px;

    @media (max-width: $mobileMax) {
        font-size: 13px;
    }
}

.verbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px 50px;
    color: $textColor;

    .verbs__ul {
        display: flex;
        flex-direction: column;
        max-width: 750px;
        width: 100%;
        padding: 0;
        list-style: none;

        .ul__header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            text-align: center;
            @include text;
        }

        .ul__li {
            background-color: $textInComponent;
            box-shadow: $shadow;
            margin-bottom: 20px;
            border-radius: 8px;
            overflow: hidden;

            .blured {
                padding: 5px 2px;
                background-color: $componentBackgroundColor;
                opacity: 30%;
                cursor: pointer;
                filter: blur(7px);
                color: $textInComponent;

                &:active {
                    filter: blur(0px);
                    opacity: 100%;
                }

                @media (max-width: $mobileMax) {
                    padding: 3px 2px;

                    &:hover {
                        filter: blur(0px);
                        opacity: 100%;
                    }
                }
            }

            .li__verb {
                display: grid;
                grid-template-columns: 1px repeat(3, 1fr);
                gap: 6px;
                margin-bottom: 3px;

                .li__speak {
                    background: none;
                    color: $componentBackgroundColor;
                    z-index: 2;
                }

                .verb__text {
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    margin-left: 3px;
                    height: 100%;
                    font-weight: 700;
                    justify-content: center;

                    @include text;
                }
            }

            .li__controller {
                display: flex;
                align-items: stretch;

                .controller__translate {
                    margin: 0 auto 0 0;
                    @include text;
                    padding: 3px;

                    @media (max-width: $mobileMax) {
                        padding: 3px;
                    }

                    :nth-child(1) {
                        margin-right: 5px;
                    }

                    :nth-child(2) {
                        padding: 3 7px;

                        @media (max-width: $mobileMax) {
                            padding: 2px;
                        }
                    }

                    .translate__text {
                        background: none;
                        color: black;
                        opacity: 100%;
                    }
                }

                .controller__button {
                    background-color: $componentBackgroundColor;
                    color: $textInComponent;
                    min-width: 40px;
                    border: none;
                    margin-right: 20px;
                    border-radius: 8px 8px 0 0;

                    @media (max-width: $mobileMax) {
                        padding: 3px;
                        margin-left: 0;
                        border-radius: 8px 0 0;
                    }
                }

                .controller__button--remove {
                    background-color: $removeColor;
                    padding: 0 6px;
                    border-radius: 8px 0 0;
                    margin-right: 0;
                    min-width: 0;

                    @media (max-width: $mobileMax) {
                        padding: 0 6px;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    .title {
        text-align: center;
        margin-top: 0;
    }

    .addingBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        gap: 12px;

        .addingBlock__td {
            margin-top: 10px;
        }

        .addingBlock__input {
            width: 150px;
            border: none;
            padding: 10px;
            box-shadow: $shadowLite;
            border-radius: 6px;
        }

        .addingBlock__button {
            width: 100px;
            border: none;
            border-radius: 6px;
            background-color: $componentBackgroundColor;
            color: $textInComponent;
            padding: 10px;
            box-shadow: $shadowLite
        }
    }
}