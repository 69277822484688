@import "../../theme";

.nav {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 15px;
}

.nav__button {
    color: $textColor;
    text-decoration: none;
    padding: 10px;
    background-color: $backgroundColor;
    transition: 0.2s;
    box-shadow: 2px 2px 8px #2224;
    font-weight: 800;
    border-radius: 5px;

    &:hover {
        transform: translateY(-4px);
    }
}

.active {
    background-color: $componentBackgroundColor;
    color: $textInComponent;
    transform: translateY(-4px);
}